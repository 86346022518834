import { useSession } from 'next-auth/react';

import { LO_PORTAL_EVENTS } from '@hl-portals/constants';

import { usePublicEvent, useScreenSize } from '@hl-portals/hooks';

type Device = 'mobile_web' | 'desktop_web';

type CoreEventData = {
  device: Device;
};

export type LoanOfficerPortalEventNames =
  typeof LO_PORTAL_EVENTS[keyof typeof LO_PORTAL_EVENTS];

export type LoanOfficerPortalEvent = {
  event_action: LoanOfficerPortalEventNames;
  event_payload?: Partial<CoreEventData> & {
    [key: string]:
      | string
      | number
      | boolean
      | unknown[]
      | Record<string, unknown>;
  };
};

export function useLoanOfficerPortalEvent() {
  const { recordPublicEvent } = usePublicEvent();
  const { data: session } = useSession();
  const { isMobile } = useScreenSize();

  function logLoanOfficerPortalEvent({
    event_action,
    event_payload,
  }: LoanOfficerPortalEvent) {
    recordPublicEvent({
      source: 'lo_portal',
      event_action,
      event_payload: {
        device: isMobile ? 'mobile_web' : 'desktop_web',
        user_name: session?.user?.name ?? 'n/a',
        user_email: session?.user?.email ?? 'n/a',
        ...event_payload,
      },
    });
  }

  return { logLoanOfficerPortalEvent };
}
