import { useEffect, useState } from 'react';

export const useSlowCallMessage = (tolerance = 5000) => {
  const [isTimerOn, setIsTimerOn] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const clear = () => {
    setIsTimerOn(false);
    setShowMsg(false);
  };

  const start = () => {
    setIsTimerOn(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isTimerOn) {
      timer = setTimeout(() => {
        setShowMsg(true);
      }, tolerance);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isTimerOn, tolerance]);

  return {
    clear,
    start,
    showMsg,
    isTimerOn,
  };
};
