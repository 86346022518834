import { Box, Button, Icon, Paragraph, SurveyModal } from '@hl-portals/ui';

import { useModal, useScreenSize } from '@hl-portals/hooks';

type ErrorFallbackPageProps = {
  loanOfficer?: {
    name?: string;
    full_name?: string;
    email?: string;
  };
  refetch?: () => void;
};

export const ErrorFallbackPage = ({
  loanOfficer,
  refetch,
}: ErrorFallbackPageProps) => {
  const { isMobile } = useScreenSize();
  const { openModal, closeModal } = useModal();

  const handleRefetch = () => {
    refetch?.();
  };

  const surveyModalProps = {
    loanOfficer,
    goBack: () => closeModal({ clearAll: true }),
  };

  return (
    <Box
      flex="1"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="2rem"
    >
      <Box
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="18px"
        bgcolor="#F8F8FB"
        width="100%"
        pb="2rem"
        borderRadius="0.5rem"
      >
        <Icon type="refreshWebPage" size={200} fill="#E8EEF9" />
        <Box
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="18px"
          p={isMobile ? '0 1rem' : '0'}
        >
          <Paragraph variant="heading-4" color="#273653" fontWeight="600">
            Content failed to load
          </Paragraph>
          <Paragraph variant="text" color="#3A3C40" textAlign="center">
            Try reloading the page. If the issue continues{' '}
            <Paragraph
              color="aaBlue"
              as="span"
              cursor="pointer"
              onClick={() => openModal(<SurveyModal {...surveyModalProps} />)}
            >
              Contact us
            </Paragraph>
            .
          </Paragraph>
        </Box>
        <Button
          size="large"
          variant="outlined"
          color="tertiary"
          onClick={handleRefetch}
          padding="0.75rem 1.5rem"
          iconLeft={<Icon type="redo" size={24} />}
          style={{
            border: '2px solid #46B6FF',
            color: '#1192E5',
          }}
        >
          Reload
        </Button>
      </Box>
    </Box>
  );
};
