import { useEffect, useState } from 'react';

import { BoxTypes, FadeIn, Paragraph } from '@hl-portals/ui';

const defaultMessages = [
  'Uploading... This might take a moment',
  "Uploading... Please don't close the window",
  'Uploading... Almost there',
];

type SlowCallMessageProps = BoxTypes & {
  interval?: number;
  messages?: string[];
};

export const SlowCallMessage = ({
  interval = 5000,
  messages = defaultMessages,
  ...props
}: SlowCallMessageProps) => {
  const [messageId, setMessageId] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageId((id) => {
        if (id === messages.length - 1) return 0;
        return id + 1;
      });
    }, interval);
    return () => clearInterval(intervalId);
  }, [interval, messages]);

  return (
    <FadeIn p="16px" borderRadius="8px" bgcolor="#273653" {...props}>
      <Paragraph color="white">{messages[messageId]}</Paragraph>
    </FadeIn>
  );
};
