import { PropsWithChildren, createContext, useContext, useState } from 'react';

export const useMultiStepLinear = (initialStep?: string) => {
  const [history, setHistory] = useState<string[]>(
    initialStep ? [initialStep] : []
  );

  const currentStep = history[history.length - 1];
  const prevStep = history[history.length - 2];
  const isFirstStep = prevStep === undefined;

  const goTo = (step: string) => {
    setHistory((prev) => [...prev, step]);
  };

  const goBack = () => {
    setHistory((prev) => prev.slice(0, -1));
  };

  const initializeHistory = (_history: string[]) => {
    setHistory(_history);
  };

  return {
    history,
    setHistory,
    currentStep,
    prevStep,
    isFirstStep,
    goTo,
    goBack,
    initializeHistory,
  };
};

type UseMultiStepLinearReturn = ReturnType<typeof useMultiStepLinear>;

const MultiStepContext = createContext<UseMultiStepLinearReturn | null>(null);

export const useMultiStepLinearContext = () => {
  const ctx = useContext(MultiStepContext);
  if (!ctx) throw new Error('Missing <MultiStep.Root />');
  return ctx;
};

const Root = ({
  children,
  ...props
}: PropsWithChildren<UseMultiStepLinearReturn>) => (
  <MultiStepContext.Provider value={props}>
    {children}
  </MultiStepContext.Provider>
);

const Step = ({ children, name }: PropsWithChildren<{ name: string }>) => {
  const { currentStep } = useMultiStepLinearContext();

  if (currentStep === name) return <>{children}</>;
  return <></>;
};

export const MultiStepLinear = {
  Root,
  Step,
};
