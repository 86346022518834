import {
  Accordion,
  Box,
  ComplianceBanner,
  FadeIn,
  Icon,
  IconTypeProp,
  Paragraph,
} from '@hl-portals/ui';

import { colors } from '@hl-portals/constants';

export const OnboardingModal = ({
  onboardingDetails,
}: {
  onboardingDetails: React.ReactNode;
}) => {
  const getProps = (label: string) => ({
    icon: 'angleRight' as IconTypeProp,
    iconPosition: 'right' as 'left' | 'right',
    borderBottom: `1px solid ${colors.coolGray4} `,
    paddingBottom: '16px',
    label: <Paragraph variant="text-bold">{label}</Paragraph>,
  });

  return (
    <FadeIn height="100%" flexDirection="column">
      <Box
        width={{ xs: '100%', md: '65rem' }}
        height={{ xs: '100%', md: '40rem' }}
        my={{ xs: '0', md: '3.5rem' }}
        px={{ xs: '0', md: '2rem' }}
        flexDirection="column"
        overflowY="auto"
      >
        <Box
          p="16px"
          mb="40px"
          gap="40px"
          border="1px solid #EEF0F6"
          borderRadius="16px"
        >
          <Box
            width="320px"
            height="100%"
            display={{ xs: 'none', md: 'flex' }}
            justifyContent="center"
            alignItems="center"
            borderRadius="8px"
            bgcolor="#F2F9FE"
          >
            <Box
              width="292px"
              height="292px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              bgcolor="#E9F6FF"
            >
              <Icon type="houseIcon" size={240} />
            </Box>
          </Box>
          {onboardingDetails}
        </Box>

        <Box pb="24px" flexDirection="column">
          <Paragraph variant="heading-4" mb="12px">
            FAQ
          </Paragraph>
          <Box flexDirection="column" gap="16px">
            <Accordion {...getProps('What is Equity Boost?')}>
              <Box flexDirection="column">
                <Paragraph mb="12px">
                  Equity Boost is an add-on to the Buy Before You Sell product,
                  designed to increase the equity unlocked from the departing
                  residence at no extra cost. By leveraging eligible assets not
                  already allocated for the new home purchase, our team can
                  assess how much additional funding can be provided without
                  upfront asset liquidation.
                </Paragraph>
                <Paragraph>
                  The extra equity is added to the loan for cash to close, and
                  in most cases (90%), clients can repay at the departing home’s
                  closing. If the sale doesn’t cover the balance, clients may
                  need to liquidate assets at that time. If repayment isn’t
                  possible, we may attach a lien to the new home.
                </Paragraph>
              </Box>
            </Accordion>

            <Accordion
              {...getProps(
                'What is the process to get approved for Equity Boost?'
              )}
            >
              <Paragraph>
                To approve Equity Boost, we first assess the client’s target
                funding needs. If the initial equity approval falls short, we
                offer Equity Boost to bridge the gap. The client or loan officer
                submits documentation of additional assets, which our
                underwriter reviews to determine how much extra equity can be
                unlocked. Once approved, we issue an updated Buy Before You Sell
                Agreement showing the increased equity available. After the
                departing residence sells, the sale proceeds pay off the equity
                unlock loan; if there’s a shortfall, the client covers the
                remaining balance with qualified assets.
              </Paragraph>
            </Accordion>
          </Box>
          <ComplianceBanner />
        </Box>
      </Box>
    </FadeIn>
  );
};
