export const LO_PORTAL_EVENTS = {
  ADDED_REAL_ESTATE_AGENT_CONTACT_INFO: 'added_real_estate_contact_info',
  ADDED_ASSISTANT_PROCESSOR_CONTACT_INFO:
    'added_assistant_processor_contact_info',
  CLICKED_SIDEBAR_EXPANDED: 'clicked_sidebar_expanded',
  CLICKED_SIDEBAR_COLLAPSED: 'clicked_sidebar_collapsed',
  CLICKED_SIDEBAR_PAYMENT_ICON: 'clicked_sidebar_payment_icon',
  CLICKED_SIDEBAR_HOME: 'clicked_sidebar_home',
  CLICKED_SIDEBAR_CONTACT_US: 'clicked_sidebar_contact_us',
  CLICKED_PIPELINE_COLUMN_HEADER: 'clicked_pipeline_column_header',
  CLICKED_PIPELINE_ROW: 'clicked_pipeline_row',
  CLICKED_PAYMENT_HISTORY_ROW: 'clicked_payment_history_row',
  CLICKED_OPEN_PAYMENTS_FAQ_MODAL: 'clicked_open_payments_faq_modal',
  CLICKED_OPEN_PAYMENTS_FAQ_QUESTION: 'clicked_open_payments_faq_question',
  CLICKED_HELP_CENTER: 'clicked_help_center',
  CLICKED_RESOURCE_CENTER: 'clicked_resource_center',
  CLICKED_CLIENT_SUBMISSION: 'client_submit_button',
  CLICKED_SHARED_TASK_BUTTON: 'clicked_shared_task_button',
  CLICKED_SHARE_TASK_LINK: 'clicked_share_task_link',
  CLICKED_PORTAL_VIEW_SEE_ALL_STAGES: 'clicked_portal_view_see_all_stages',
  CLICKED_TRANSACTION_VIEW_SEE_ALL_STAGES:
    'clicked_transaction_view_see_all_stages',
  CLICKED_EQUITY_UNLOCK_LEARN_MORE_BANNER:
    'clicked_equity_unlock_learn_more_banner',
  CLICKED_START_REVIEW_BBYS_AGREEMENT_TASK:
    'clicked_start_review_bbys_agreement_task',
  CLICKED_SUBMIT_BBYS_AGREEMENT_TASK: 'clicked_submit_bbys_agreement_task',

  START_KEY_NUMBERS_REVIEW_TASK: 'start_key_numbers_review_task',
  COMPLETE_KEY_NUMBERS_REVIEW_TASK: 'complete_key_numbers_review_task',
  START_BACKUP_OFFER_REVIEW_TASK: 'start_backup_offer_review_task',
  COMPLETE_BACKUP_OFFER_REVIEW_TASK: 'complete_backup_offer_review_task',
  START_INVITE_CLIENT_TO_SIGN_REVIEW_TASK:
    'start_invite_client_to_sign_review_task',
  COMPLETE_INVITE_CLIENT_TO_SIGN_REVIEW_TASK:
    'complete_invite_client_to_sign_review_task',

  CLICKED_AGREEMENT_DESCRIPTION: 'clicked_agreement_description',

  COMPLETED_PHOTO_UPLOAD_TASK: 'completed_photo_upload_task',
  COMPLETED_SCHEDULE_NOTARY_TASK: 'completed_schedule_notary_task',
  COMPLETED_SURVEY_MODAL: 'completed_survey_modal',
  EMAIL_LRM: 'email_lender_relationship_manager_call',
  SCHEDULED_LRM_CALL: 'scheduled_lender_relationship_manager_call',
  SEARCHED_TRANSACTION: 'searched_transaction',
  DAILY_ACTIVE_USER: 'daily_active_user',
  CAPTURE_REFERRER: 'capture_referrer',
} as const;

export type LoanOfficerPortalEventNames =
  typeof LO_PORTAL_EVENTS[keyof typeof LO_PORTAL_EVENTS];
