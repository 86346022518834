import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { useScreenSize } from '@hl-portals/hooks';

export const FeedbackSentThankYouMessage = ({
  goBack,
}: {
  goBack: () => void;
}): React.ReactElement => {
  const { isMobile } = useScreenSize();
  return (
    <Box
      width="100%"
      height={{ xs: '50vh', md: '100%' }}
      display="flex"
      flexDirection="column"
      justifyContent={isMobile ? 'space-between' : 'center'}
      alignItems="center"
    >
      <Box
        flex={isMobile ? '1' : 'none'}
        width={{ xs: '100%', md: '773px' }}
        height={{ xs: 'auto', md: '70vh' }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="40px"
        px="16px"
        gap="16px"
      >
        <Icon type="starInPalm" size={100} />
        <Paragraph variant="heading-3" textAlign="center">
          Feedback sent, thank you!
        </Paragraph>
        <Paragraph variant="text" textAlign="center">
          Your feedback helps us improve the product.
        </Paragraph>
        {!isMobile && (
          <Button
            width="auto"
            color="primary"
            size="large"
            mt="8px"
            onClick={goBack}
          >
            Go back
          </Button>
        )}
      </Box>
      {isMobile && (
        <Button
          width="100%"
          color="primary"
          size="large"
          mt="8px"
          onClick={goBack}
        >
          Go back
        </Button>
      )}
    </Box>
  );
};
