import { useFlags } from 'flagsmith/react';

type Skip1003AndCreditReportList = {
  partner_slug: string | null;
};

export const useSkip1003AndCreditReportList = ({
  partner_slug,
}: Skip1003AndCreditReportList): boolean => {
  const {
    'allow-skip-1003-and-credit-report-list': { enabled, value },
  } = useFlags(['allow-skip-1003-and-credit-report-list']);

  if (!partner_slug) return false;

  const { allow_skip_1003_and_credit_report_list } = value
    ? (JSON.parse(value.toString()) as {
        allow_skip_1003_and_credit_report_list: string[];
      })
    : { allow_skip_1003_and_credit_report_list: [] };

  const isAllowToSkip =
    allow_skip_1003_and_credit_report_list.includes(partner_slug);

  return enabled && isAllowToSkip;
};
