import { Box, Icon, Paragraph } from '@hl-portals/ui';

export const ComplianceBanner = () => {
  return (
    <Box
      flex="1"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      py="1.125rem"
    >
      <Paragraph variant="text-small" fontWeight="400" color="gray500">
        HomeLight Home Loans, Inc. NMLS 1529229
      </Paragraph>
      <Icon type="equalHousingOpportunity" size={32} />
    </Box>
  );
};
